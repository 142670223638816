// app/home/ShelvesClient.tsx (Client Component)
"use client";

import { useEffect } from "react";
import { Shelf } from "@/graphql/generated/types";
import {
// useShelvesStore,
useStatusShelvesStore } from "@/store/useStatusShelvesStore";
interface ShelvesClientProps {
  shelves: Shelf[];
}
export default function ShelvesClient({
  shelves
}: ShelvesClientProps) {
  const setShelves = useStatusShelvesStore(state => state.setShelves);
  useEffect(() => {
    if (shelves && shelves.length > 0) {
      setShelves(shelves);
    }
  }, [shelves, setShelves]);
  return null;
}