import React from "react";
import { cn } from "@/lib/utils";
interface PopoverContainerProps {
  isOpen: boolean;
  popoverClassName?: string;
  arrowClassName?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  animate?: boolean;
}
const PopoverContainer: React.FC<PopoverContainerProps> = ({
  isOpen,
  popoverClassName,
  arrowClassName,
  children,
  style,
  animate = true
}) => {
  if (!isOpen) {
    return null;
  }
  return <div style={{
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.35)",
    // filter: "drop-shadow(0 5px 15px rgba(0, 0, 0, 0.35))",
    ...style
  }} className={cn("absolute z-20 top-16 rounded-[8px] border border-[#bdbdbd]/10 bg-[#1d1e2f] text-sm text-white", animate && (isOpen ? "animate-in" : "animate-out fade-out-0", isOpen ? "fade-in-0" : "zoom-out-95", isOpen && "zoom-in-95 slide-in-from-top-8"), popoverClassName)} data-sentry-component="PopoverContainer" data-sentry-source-file="PopoverContainer.tsx">
      <div className={cn("absolute hidden border -translate-x-1/2 left-1/2 -top-[10px] border-[#bdbdbd]/10 bg-[#1d1e2f] size-[18px] rotate-[135deg]", arrowClassName)}></div>
      {children}
    </div>;
};
export default PopoverContainer;