"use client";

import React from "react";
import Link from "next/link";
import { FaChevronRight } from "react-icons/fa";
import { cn } from "@/lib/utils";
import { useResponsive } from "@/hooks/useResponsive";
interface BookCarouselHeaderProps {
  title: string;
  url?: string;
  hideMore?: boolean;
  urlText?: string;
}
const BookCarouselHeader: React.FC<BookCarouselHeaderProps> = ({
  title,
  url,
  hideMore,
  urlText
}) => {
  const {
    isMobile
  } = useResponsive();
  return <div className={cn("mb-5 max-sm:mb-4 flex max-sm:justify-between items-center gap-5")} data-sentry-component="BookCarouselHeader" data-sentry-source-file="BookCarouselHeader.tsx">
      <h3 className="text-xl sm:text-2xl font-semibold text-white">{title}</h3>
      {!hideMore && <Link href={url ?? ""} className="font-medium text-white underline-offset-[5px] hover:underline flex items-center gap-1">
          <span>{urlText ?? isMobile ? "See All" : "See More"}</span>
          {!urlText && !isMobile && <FaChevronRight size={12} />}
        </Link>}
    </div>;
};
export default BookCarouselHeader;