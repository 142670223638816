"use client";

import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { HiOutlineHeart } from "react-icons/hi2";
import { useLikeResource } from "@/hooks/useLikeResource";
import { cn } from "@/lib/utils";
import { formatToShortenNumber } from "@/utils";
interface LikeButtonProps {
  resourceId: string;
  resourceType: "review" | "reviewComment";
  likedByMe: boolean;
  likesCount: number;
}

// const starColors = ["#F91880", "#A5125A", "#FF80C0", "#F97316", "#FEF08A"]

const heartVariants = {
  liked: {
    scale: [1, 1.4, 0.9, 1],
    transition: {
      duration: 0.4,
      ease: "easeOut"
    }
  },
  unliked: {
    scale: [1, 0.8, 1.1, 1],
    transition: {
      duration: 0.4,
      ease: "easeOut"
    }
  }
};

// const sparkleVariants = {
//   initial: { opacity: 0, scale: 0 },
//   animate: (custom: any) => ({
//     x: custom.x,
//     y: custom.y,
//     rotate: custom.rotate,
//     scale: 1,
//     opacity: 1,
//     transition: {
//       duration: 0.3,
//       ease: "easeOut",
//     },
//   }),
//   exit: {
//     opacity: 0,
//     scale: 0.5,
//     transition: { duration: 0.2, ease: "easeIn" },
//   },
// }

const counterVariants = {
  enter: (direction: number) => ({
    y: direction > 0 ? "100%" : "-100%",
    opacity: 0
  }),
  center: {
    y: "0%",
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: (direction: number) => ({
    y: direction > 0 ? "-100%" : "100%",
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn"
    }
  })
};

// const heartVariants = {
//   initial: { scale: 1 },
//   liked: {
//     scale: [1, 1.2, 1],
//     transition: { duration: 0.3 },
//   },
//   unliked: {
//     scale: [1, 0.9, 1],
//     transition: { duration: 0.3 },
//   },
// }

const LikeButton = ({
  resourceId,
  resourceType,
  likedByMe,
  likesCount
}: LikeButtonProps) => {
  // const [showParticles, setShowParticles] = React.useState(false)
  // const [liked, setLiked] = useState(likedByMe)
  // const [likesCount, setLikesCount] = useState(1000)
  const [shortenedLikes, setShortenedLikes] = useState(formatToShortenNumber(likesCount));
  const [direction, setDirection] = useState(0);
  // const [prevCount, setPrevCount] = useState(likesCount)

  // const [showSparkles, setShowSparkles] = useState(false)
  const prevCountRef = useRef(likesCount);
  console.log(likedByMe, "likedByMe");
  const {
    toggleLike
  } = useLikeResource({
    resourceType,
    resourceId,
    likesCount,
    likedByMe
  });
  useEffect(() => {
    const prevCount = prevCountRef.current;
    console.log("revCount", prevCount);
    if (likesCount > prevCount) {
      setDirection(1);
    } else if (likesCount < prevCount) {
      setDirection(-1);
    } else {
      setDirection(0);
    }
    // Update prevCount so next render can compare properly
    const newShort = formatToShortenNumber(likesCount);
    if (newShort !== shortenedLikes) {
      setShortenedLikes(newShort);
    }
    // setPrevCount(likesCount)
  }, [likesCount, shortenedLikes]);
  const handleClick = async () => {
    try {
      await toggleLike();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleClick = () => {
  //   // This is where you'd call toggleLike() from your useLikeResource hook
  //   // But I'll just simulate it here:
  //   setLiked(!liked)

  //   if (!liked) {
  //     // We’re transitioning from unlikedByMe→ liked
  //     setLikesCount((prev) => prev + 1)
  //     // Show sparkles for a moment
  //     setShowSparkles(true)
  //     // Hide sparkles after ~500ms
  //     setTimeout(() => setShowSparkles(false), 500)
  //   } else {
  //     // likedByMe→ unliked
  //     setLikesCount((prev) => (prev > 0 ? prev - 1 : 0))
  //     // No sparkles on unlike (Twitter doesn’t do a sparkle when removing a like)
  //   }
  // }

  return <>
      <button onClick={handleClick} aria-pressed={likedByMe ? true : false} aria-label={likedByMe ? "Unlike" : "Like"}>
        <div className={cn("flex group mt-[0.5px] -ml-2 mr-2 -space-x-0.5 items-center focus:outline-none rounded-full")}>
          <motion.div animate={likedByMe ? "liked" : "unliked"} variants={heartVariants} initial="initial" className="-mb-0.5 flex items-center size-8 rounded-[9999px] sm:group-hover:bg-white/[4%] justify-center " data-sentry-element="unknown" data-sentry-source-file="LikeButton.tsx">
            <HiOutlineHeart className={`h-[18px] w-[18px] sm:group-hover:fill-[#F91880] sm:group-hover:text-[#F91880] ${likedByMe ? "fill-[#F91880] text-[#F91880] transition-colors duration-100" : "text-white transition-colors duration-100"}`} data-sentry-element="HiOutlineHeart" data-sentry-source-file="LikeButton.tsx" />
            {/* {showParticles && <LikeParticles />} */}
          </motion.div>
          {/* <div className="relative h-5 overflow-hidden text-sm text-white">
           <AnimatePresence initial={false}>
            <motion.span
              key={shortenedLikes}
              variants={counterVariants}
              initial="enter"
              animate="animate"
              exit="exit"
              className={cn(
                "relative left-0 right-0 text-sm sm:text-sm sm:group-hover:text-[#F91880] sm:font-normal sm:text-white font-semibold text-[#e6e6fa]/90",
                liked
                  ? "text-[#F91880] sm:text-[#F91880]"
                  : "sm:group-hover:text-[#F91880]"
              )}
            >
              {shortenedLikes}
            </motion.span>
           </AnimatePresence>
           </div> */}

          <div className="relative justify-start inline-block h-5 text-sm text-white">
            <AnimatePresence initial={false} custom={direction} data-sentry-element="AnimatePresence" data-sentry-source-file="LikeButton.tsx">
              <motion.span key={shortenedLikes} custom={direction} variants={counterVariants} initial="enter" animate="center" exit="exit" className={cn("absolute left-0 text-center text-sm sm:group-hover:text-[#F91880] sm:font-normal text-white font-semibold", likedByMe ? "text-[#F91880] sm:text-[#F91880]" : "text-white sm:group-hover:text-[#F91880]")} data-sentry-element="unknown" data-sentry-source-file="LikeButton.tsx">
                {shortenedLikes}
              </motion.span>
            </AnimatePresence>
          </div>
        </div>
      </button>
    </>;
};
export default React.memo(LikeButton);

// const particles = Array.from({ length: 5 })

// const LikeParticles: React.FC = () => (
//   <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
//     {particles.map((_, index) => {
//       const angle = (index / particles.length) * 2 * Math.PI
//       const radius = 20

//       return (
//         <motion.div
//           key={index}
//           className="bg-[#F91880] size-40 rounded-full"
//           style={{ width: 30, height: 30 }}
//           initial={{ opacity: 1, scale: 0 }}
//           animate={{
//             opacity: 0,
//             scale: 1,
//             x: Math.cos(angle) * radius,
//             y: Math.sin(angle) * radius,
//           }}
//           transition={{ duration: 0.75, ease: "easeOut" }}
//         />
//       )
//     })}
//   </div>
// )