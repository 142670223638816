"use client";

import { useEffect } from "react";
import Navbar from "@/components/navbar/Navbar";
import { useUser } from "@/contexts/user";
import Footer from "@/components/shared/Footer";
import { useQuery } from "@apollo/client";
import { MyBasicInfoDocument
// MyUsernameDocument,
} from "@/graphql/generated/types";
export default function GlobalError({
  error
}:
// reset,
{
  error: Error;
  // reset: () => void
}) {
  const {
    user: loggedInUser
  } = useUser();
  const currentYear = new Date().getFullYear();
  let user = null;
  const {
    data: myData
  } = useQuery(MyBasicInfoDocument, {
    skip: !loggedInUser
  });
  if (myData?.me?.username) {
    user = myData?.me;
  }
  useEffect(() => {
    console.error("Global server error caught:", error);
  }, [error]);
  return <>
      <Navbar isLoggedIn={!!user} user={user} data-sentry-element="Navbar" data-sentry-source-file="error.tsx" />

      <div className="text-white px-5 flex items-center flex-col justify-center min-h-[calc(100vh-184px)]            ">
        <div>
          <div className="text-xl flex items-center sm:text-[180px] font-semibold leading-[44px] sm:leading-[120px] text-white/90">
            <span className="text-[80px] sm:text-[120px]">5</span>
            <span className="text-[100px] sm:text-[160px]">0</span>
            <span className="text-[80px] sm:text-[120px]">0</span>
          </div>
        </div>
        <h2 className="text-2xl leading-10 text-[#e0e0e0] sm:text-[36px] mt-8 sm:mt-4 sm:leading-[50px] font-semibold">
          Oops! Something went wrong on our end.
        </h2>
      </div>
      <Footer currentYear={currentYear} data-sentry-element="Footer" data-sentry-source-file="error.tsx" />
    </>;
}