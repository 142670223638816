import Link from "next/link";
import React from "react";
import { FaRedditAlien } from "react-icons/fa";
import { FaBluesky } from "react-icons/fa6";
const Footer = ({
  currentYear = 2024
}: {
  currentYear: number;
}) => {
  return <footer className="w-full max-sm:px-[73px] max-sm:gap-6 max-sm:flex-col-reverse sm:px-20 flex items-center justify-between font-medium text-sm bg-[#181A1B] max-sm:pt-6 max-sm:pb-[29px] text-white/40 h-full sm:h-28" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <span className="sm:hidden flex items-center gap-1">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
          <g clipPath="url(#clip0_2739_19441)" data-sentry-element="g" data-sentry-source-file="Footer.tsx">
            <path d="M0.875 7C0.875 8.62445 1.52031 10.1824 2.66897 11.331C3.81763 12.4797 5.37555 13.125 7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875C5.37555 0.875 3.81763 1.52031 2.66897 2.66897C1.52031 3.81763 0.875 5.37555 0.875 7ZM14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7ZM7.12775 4.368C6.06725 4.368 5.44162 5.173 5.44162 6.55725V7.48475C5.44162 8.85938 6.05675 9.639 7.12775 9.639C7.98438 9.639 8.56363 9.12625 8.64062 8.39825H9.77375V8.47962C9.68625 9.74662 8.589 10.6383 7.1225 10.6383C5.29288 10.6383 4.26213 9.46925 4.26213 7.48563V6.54675C4.26213 4.56838 5.313 3.3635 7.12337 3.3635C8.59425 3.3635 9.6915 4.28575 9.77375 5.614V5.691H8.64062C8.56363 4.92188 7.96863 4.368 7.12775 4.368Z" fill="white" fillOpacity="0.4" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </g>
          <defs data-sentry-element="defs" data-sentry-source-file="Footer.tsx">
            <clipPath id="clip0_2739_19441" data-sentry-element="clipPath" data-sentry-source-file="Footer.tsx">
              <rect width="14" height="14" fill="white" data-sentry-element="rect" data-sentry-source-file="Footer.tsx" />
            </clipPath>
          </defs>
        </svg>
        {currentYear} Kaguya Media Pvt Ltd
      </span>

      <div className="flex max-sm:flex-wrap max-sm:mt-2 max-sm:gap-y-6 max-sm:justify-center gap-3 items-center">
        <span className="max-sm:hidden flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <g clipPath="url(#clip0_2739_19441)" data-sentry-element="g" data-sentry-source-file="Footer.tsx">
              <path d="M0.875 7C0.875 8.62445 1.52031 10.1824 2.66897 11.331C3.81763 12.4797 5.37555 13.125 7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875C5.37555 0.875 3.81763 1.52031 2.66897 2.66897C1.52031 3.81763 0.875 5.37555 0.875 7ZM14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7ZM7.12775 4.368C6.06725 4.368 5.44162 5.173 5.44162 6.55725V7.48475C5.44162 8.85938 6.05675 9.639 7.12775 9.639C7.98438 9.639 8.56363 9.12625 8.64062 8.39825H9.77375V8.47962C9.68625 9.74662 8.589 10.6383 7.1225 10.6383C5.29288 10.6383 4.26213 9.46925 4.26213 7.48563V6.54675C4.26213 4.56838 5.313 3.3635 7.12337 3.3635C8.59425 3.3635 9.6915 4.28575 9.77375 5.614V5.691H8.64062C8.56363 4.92188 7.96863 4.368 7.12775 4.368Z" fill="white" fillOpacity="0.4" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
            </g>
            <defs data-sentry-element="defs" data-sentry-source-file="Footer.tsx">
              <clipPath id="clip0_2739_19441" data-sentry-element="clipPath" data-sentry-source-file="Footer.tsx">
                <rect width="14" height="14" fill="white" data-sentry-element="rect" data-sentry-source-file="Footer.tsx" />
              </clipPath>
            </defs>
          </svg>
          {currentYear} Kaguya Media Pvt Ltd
        </span>
        <span className="max-sm:hidden text-[9px] ">•</span>
        <Link href="/privacy-policy" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Privacy
        </Link>
        <span className="text-[9px] ">•</span>
        <Link href="/terms" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Terms
        </Link>
        <span className="text-[9px]">•</span>
        <Link href="/community-guidelines" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Guidelines
        </Link>
        <span className="max-sm:hidden text-[9px] ">•</span>
        <Link className="hover:underline" href={process.env.NEXT_PUBLIC_DISCORD_HELP_CHANNEL! ?? ""} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Help & Support
        </Link>
        <span className="text-[9px] ">•</span>
        <Link href="/team" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          Team
        </Link>
      </div>

      <div className="flex gap-4 items-center">
        <Link href={"https://reddit.com/r/kaguya"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <FaRedditAlien size={21} className="text-white" data-sentry-element="FaRedditAlien" data-sentry-source-file="Footer.tsx" />
        </Link>
        <Link href={"https://x.com/KaguyaMedia"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <path d="M15.2716 2.54761H18.0828L11.9411 9.56718L19.1663 19.1192H13.509L9.07804 13.326L4.00796 19.1192H1.19503L7.7642 11.611L0.833008 2.54761H6.63393L10.6392 7.84288L15.2716 2.54761ZM14.2849 17.4366H15.8427L5.7875 4.14188H4.11589L14.2849 17.4366Z" fill="white" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </svg>
        </Link>
        <Link href={"https://bsky.app/profile/kaguya.io"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <FaBluesky size={21} className="text-white" data-sentry-element="FaBluesky" data-sentry-source-file="Footer.tsx" />
        </Link>
        <Link href={"https://instagram.com/kaguyamedia/"} target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.61126 0.960938C3.47481 0.960937 2.38488 1.41228 1.58114 2.21572C0.777391 3.01915 0.325632 4.10891 0.325195 5.24536V16.6749C0.325195 17.8116 0.776762 18.9018 1.58056 19.7056C2.38435 20.5094 3.47453 20.9609 4.61126 20.9609H16.0408C17.1772 20.9605 18.267 20.5087 19.0704 19.705C19.8739 18.9013 20.3252 17.8113 20.3252 16.6749V5.24536C20.3248 4.10919 19.8732 3.01969 19.0698 2.2163C18.2664 1.41291 17.1769 0.961374 16.0408 0.960938H4.61126ZM17.2736 5.25194C17.2736 5.57934 17.1435 5.89333 16.912 6.12484C16.6805 6.35635 16.3665 6.48641 16.0391 6.48641C15.7117 6.48641 15.3977 6.35635 15.1662 6.12484C14.9347 5.89333 14.8047 5.57934 14.8047 5.25194C14.8047 4.92454 14.9347 4.61055 15.1662 4.37904C15.3977 4.14754 15.7117 4.01748 16.0391 4.01748C16.3665 4.01748 16.6805 4.14754 16.912 4.37904C17.1435 4.61055 17.2736 4.92454 17.2736 5.25194ZM10.3277 7.53653C9.41967 7.53653 8.54887 7.89723 7.90682 8.53927C7.26478 9.18132 6.90408 10.0521 6.90408 10.9601C6.90408 11.8681 7.26478 12.7389 7.90682 13.381C8.54887 14.023 9.41967 14.3837 10.3277 14.3837C11.2357 14.3837 12.1065 14.023 12.7485 13.381C13.3906 12.7389 13.7513 11.8681 13.7513 10.9601C13.7513 10.0521 13.3906 9.18132 12.7485 8.53927C12.1065 7.89723 11.2357 7.53653 10.3277 7.53653ZM5.25648 10.9601C5.25648 9.61559 5.79059 8.32613 6.74131 7.37541C7.69203 6.42468 8.98149 5.89057 10.326 5.89057C11.6705 5.89057 12.96 6.42468 13.9107 7.37541C14.8614 8.32613 15.3956 9.61559 15.3956 10.9601C15.3956 12.3046 14.8614 13.5941 13.9107 14.5448C12.96 15.4955 11.6705 16.0297 10.326 16.0297C8.98149 16.0297 7.69203 15.4955 6.74131 14.5448C5.79059 13.5941 5.25648 12.3046 5.25648 10.9601Z" fill="white" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </svg>
        </Link>
        <Link href="https://discord.gg/wRjmwVxWEh" target="_blank" className="rounded-[50%] flex items-center justify-center cursor-pointer bg-white/[4%] size-11" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
          <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
            <path d="M20.6222 2.46843C19.117 1.76419 17.5076 1.25237 15.825 0.960938C15.6183 1.33454 15.3769 1.83705 15.2104 2.2368C13.4218 1.9678 11.6496 1.9678 9.89379 2.2368C9.72737 1.83705 9.48047 1.33454 9.27197 0.960938C7.58754 1.25237 5.97626 1.76607 4.47106 2.47216C1.43505 7.05996 0.612043 11.5338 1.02355 15.9441C3.03719 17.4479 4.98864 18.3613 6.90717 18.9591C7.38086 18.3071 7.80333 17.6141 8.16728 16.8837C7.47413 16.6204 6.81024 16.2953 6.18294 15.918C6.34935 15.7947 6.51214 15.6658 6.66941 15.5332C10.4955 17.3227 14.6526 17.3227 18.433 15.5332C18.5921 15.6658 18.7549 15.7947 18.9195 15.918C18.2903 16.2972 17.6246 16.6222 16.9315 16.8856C17.2954 17.6141 17.7161 18.309 18.1916 18.9609C20.112 18.3632 22.0652 17.4497 24.0789 15.9441C24.5617 10.8314 23.254 6.39868 20.6222 2.46843ZM8.68853 13.2318C7.53998 13.2318 6.59808 12.1596 6.59808 10.8539C6.59808 9.54812 7.51987 8.47403 8.68853 8.47403C9.85723 8.47403 10.7991 9.54624 10.779 10.8539C10.7808 12.1596 9.85723 13.2318 8.68853 13.2318ZM16.4139 13.2318C15.2653 13.2318 14.3234 12.1596 14.3234 10.8539C14.3234 9.54812 15.2452 8.47403 16.4139 8.47403C17.5825 8.47403 18.5245 9.54624 18.5043 10.8539C18.5043 12.1596 17.5825 13.2318 16.4139 13.2318Z" fill="white" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
          </svg>
        </Link>
      </div>
    </footer>;
};
export default Footer;