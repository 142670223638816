import { Shelf } from "@/graphql/generated/types"
import { create } from "zustand"

interface StatusShelvesStore {
  status: string | null
  shelves: Shelf[] | null
  myShelves: Shelf[] | null
  setMyShelves: (shelves: Shelf[] | null) => void
  setStatus: (status: string | null) => void
  setShelves: (shelves: Shelf[] | null) => void
}

export const useStatusShelvesStore = create<StatusShelvesStore>((set) => ({
  status: null,
  shelves: [],
  setStatus: (status) => set({ status }),
  setShelves: (shelves) => set({ shelves }),
  myShelves: null,
  setMyShelves: (shelves) => set({ myShelves: shelves }),
}))

interface ShelvesStore {
  shelves: Shelf[]
  setShelves: (shelves: Shelf[]) => void
  addShelf: (shelf: Shelf) => void
  removeShelf: (shelf: Shelf) => void
}

export const useShelvesStore = create<ShelvesStore>((set) => ({
  shelves: [],
  setShelves: (shelves) => set({ shelves }),
  addShelf: (shelf) =>
    set((state) => ({ shelves: [...state?.shelves, shelf] })),
  removeShelf: (shelf) =>
    set((state) => ({
      shelves: state?.shelves.filter((s) => s.id !== shelf.id),
    })),
}))
