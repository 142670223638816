"use client";

import Link from "next/link";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { formatToShortenNumber } from "@/utils";
import { HiMiniStar } from "react-icons/hi2";
import React from "react";
import { Card, CardContent } from "../ui/card";
import { cn } from "@/lib/utils";
import { Skeleton } from "../ui/skeleton";
import ReviewActionsMenu from "../reviews/ReviewActionsMenu";
import AddToShelfButton from "./AddToShelfButton";
// import ReadMoreText from "./ReadMoreText"
import BookCover from "./BookCover";
import LikeButton from "../reviews/LikeButton";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Book, BookByIdDocument, ReadingStatusEnum, Review } from "@/graphql/generated/types";
import dynamic from "next/dynamic";
import getImageUrl from "@/utils/getImageUrl";
import { useUser } from "@/contexts/user";
import ShareButton from "./ShareButton";
// import { Button } from "../ui/button"

const ReadMoreText = dynamic(() => import("@/components/shared/ReadMoreText"), {
  ssr: false,
  loading: () => <div className="my-[18px] text-sm font-normal text-[#E6E6FA]/90">
      <Skeleton className="h-48 w-full" />
    </div>
});
dayjs.extend(relativeTime);
interface ReviewProps {
  bookId?: Review["bookId"];
  user?: Review["user"];
  id?: Review["id"];
  likedByMe?: Review["likedByMe"];
  likesCount?: Review["likesCount"];
  className?: string;
  commentsCount?: Review["commentsCount"];
  updatedAt?: Review["updatedAt"];
  content?: Review["content"];
  textClassName?: string;
}
const TrendingReviewsThisWeekCard = ({
  id,
  user,
  className,
  bookId,
  content,
  likedByMe,
  likesCount,
  commentsCount,
  updatedAt,
  bookCoverClassName,
  textClassName,
  hideAuthor = false
}: ReviewProps & {
  hideAuthor?: boolean;
  bookCoverClassName?: string;
}) => {
  const {
    data: bookData,
    loading: bookDataLoading
  } = useQuery(BookByIdDocument, {
    variables: {
      id: bookId as string
    }
  });
  const {
    data: userData,
    loading: userDataLoading
  } = useQuery(gql`
      query User($id: ID!, $bookId: ID!) {
        user(id: $id) {
          id
          username
          avatarId
          ratingForBook(bookId: $bookId) {
            rating
            userId
            bookId
          }
        }
      }
    `, {
    variables: {
      id: user?.id,
      bookId: bookId
    }
  });

  // const { data: reviewData } = await getClient().query({
  //   query: GetReviewDocument,
  //   variables: {
  //     id: id as string,
  //   },
  //   errorPolicy: "all",
  // })

  const book = bookData?.book;
  const {
    user: userAuth
  } = useUser();
  const myUserId = userAuth?.id;
  const avatarUrl = getImageUrl(userData?.user?.avatarId as string, "avatar") as string;
  return <Card className={cn(" rounded-none max-sm:rounded-[8px] max-sm:bg-[#232533] pt-5 pb-6 sm:py-6 sm:pr-7 max-sm:px-4 shadow-none sm:first:pt-0", className)} data-sentry-element="Card" data-sentry-component="TrendingReviewsThisWeekCard" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
      <CardContent className="flex w-full max-sm:flex-col gap-4 p-0" data-sentry-element="CardContent" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
        <div className="flex max-sm:gap-4 sm:gap-8 sm:px-0 max-sm:items-start sm:py-5 ">
          <div className={cn("sm:h-[144px] sm:w-[96px] h-[120px] w-20 flex-shrink-0 overflow-hidden rounded-[4px] sm:rounded-[8px]", bookCoverClassName)}>
            {!book || bookDataLoading ? <Skeleton className="h-full w-full" /> : <BookCover book={book as Book} className="h-full rounded-[4px] sm:rounded-[8px] object-cover object-center" />}
          </div>

          <div className="flex flex-col">
            {/* <span
             className={cn(
              "text-lg text-[#94A8F8]",
              type === "Deep Dive" && "text-[#6BC589]",
              type === "funny" && "text-[#FFA432]"
             )}
             >
             # {capitalize(type)}
             </span> */}
            {bookDataLoading || !book ? <Skeleton className="h-5 w-full" /> : <Link href={book?.slug ? `/books/${book?.slug}` : ""} className=" underline-offset-2 hover:underline">
                {" "}
                <h4 className="line-clamp-2 mb-2.5 text-xl sm:text-2xl font-extrabold max-sm:leading-6 sm:font-semibold text-white">
                  {book?.title}
                </h4>
              </Link>}

            {userDataLoading || !userData ? <Skeleton className="h-4 w-full mt-2" /> : <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2 sm:gap-[13px] text-white">
                  {!hideAuthor && <div className="flex items-center justify-center gap-2">
                      <Link href={`/users/${userData?.user?.username ?? ""}`}>
                        <Avatar className="size-[26px] sm:h-8 sm:w-8 sm:border-2 border-white">
                          <AvatarImage width={32} height={32} src={avatarUrl} alt="author" />
                          <AvatarFallback fallbackSize={20}>U</AvatarFallback>
                        </Avatar>
                      </Link>
                      <Link href={`/users/${userData?.user?.username ?? ""}`}>
                        <span className="sm:text-base text-sm font-semibold">
                          {userData?.user?.username}
                        </span>
                      </Link>
                    </div>}
                  {userData?.user?.ratingForBook?.rating ? <div className="flex items-center gap-0.5 sm:gap-1">
                      <span className="text-sm sm:text-base font-medium">
                        {userData?.user?.ratingForBook?.rating}
                      </span>
                      <HiMiniStar color="#FFA432" className="sm:size-[18px]" />
                    </div> : ""}
                  <span className="-ml-0.5 max-sm:hidden text-[15px] leading-[18px] font-light">
                    {dayjs(updatedAt).fromNow()}
                  </span>
                </div>

                <div className="sm:hidden">
                  <AddToShelfButton buttonClassName="w-[160px] justify-start" selectedClassName="max-sm:bg-white/[6%]" initialStatus={book?.myReadingStatus?.status as ReadingStatusEnum} bookId={bookId} />
                </div>
              </div>}

            <div className="max-sm:hidden">
              {false ? <Skeleton className="mt-2.5 h-[174px] w-full" /> : <ReadMoreText isReview contentJSON={content} mobileLength={640} desktopLength={640} className={cn("mb-4 mt-2.5 whitespace-pre-line text-base font-normal text-[#f4f4f4] sm:text-[#E6E6FA]", textClassName)} />}
              <div className="flex items-center h-8 gap-[14px] text-xs sm:text-sm sm:font-normal font-semibold text-white sm:text-[#e6e6fa]/90">
                {/* <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.24909 14.6528L8.24837 14.6521C6.30729 12.8919 4.7364 11.4645 3.64494 10.1292C2.55898 8.80059 2 7.62437 2 6.375C2 4.33424 3.59055 2.75 5.625 2.75C6.77885 2.75 7.89456 3.28973 8.62058 4.13564L9 4.57771L9.37942 4.13564C10.1054 3.28973 11.2212 2.75 12.375 2.75C14.4095 2.75 16 4.33424 16 6.375C16 7.62437 15.441 8.80059 14.3551 10.1292C13.2636 11.4645 11.6927 12.8919 9.75163 14.6521L9.75091 14.6528L9 15.3363L8.24909 14.6528Z"
                      stroke="white"
                    />
                  </svg> */}

                <LikeButton likedByMe={likedByMe as boolean} likesCount={likesCount as number} resourceId={id as string} resourceType={"review"} data-sentry-element="LikeButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

                {/* <span>{formatToShortenNumber(likesCount)}</span> */}

                <Link href={`${process.env.NEXT_PUBLIC_BASE_URL ?? ""}/books/${book?.slug ?? ""}/reviews/${userData?.user?.username ?? ""}`} className="flex items-center gap-1 -space-x-1.5 group" data-sentry-element="Link" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                  <div className="sm:size-8 max-sm:mr-1 sm:group-hover:bg-white/[4%] rounded-[9999px] flex items-center justify-center">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                      <path d="M8.99956 15.75C10.4718 15.7497 11.9035 15.268 13.0766 14.3785C14.2496 13.489 15.0997 12.2403 15.4973 10.8228C15.8948 9.40531 15.8181 7.89669 15.2787 6.52687C14.7393 5.15704 13.7668 4.00113 12.5095 3.23527C11.2522 2.46941 9.77897 2.1356 8.31434 2.28472C6.84971 2.43384 5.474 3.05771 4.39684 4.06125C3.31968 5.0648 2.60014 6.39298 2.34788 7.84341C2.09563 9.29384 2.32448 10.787 2.99956 12.0953L2.24956 15.75L5.90431 15C6.83131 15.4793 7.88431 15.75 8.99956 15.75Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" className="sm:group-hover:stroke-[#50DDF2]" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                      <path d="M5.625 9H5.6325V9.0075H5.625V9ZM9 9H9.0075V9.0075H9V9ZM12.375 9H12.3825V9.0075H12.375V9Z" stroke="white" strokeWidth="2" strokeLinejoin="round" className="sm:group-hover:stroke-[#50DDF2]" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                    </svg>
                  </div>

                  <span className="sm:group-hover:text-[#50DDF2] font-semibold sm:font-normal text-sm">
                    {formatToShortenNumber(commentsCount as number)}
                  </span>
                </Link>

                <ShareButton shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${book?.slug}/reviews/${userData?.user?.username}`} trigger={<div className="group text-sm font-semibold text-white sm:font-normal mr-1.5 cursor-pointer flex items-center justify-center p-0">
                      <div className="sm:size-8 max-sm:mr-1 sm:group-hover:bg-white/[4%] rounded-[9999px] flex items-center justify-center">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.9333 6.46276V2.99609L17 9.06276L10.9333 15.1294V11.5761C6.6 11.5761 3.56667 12.9628 1.4 15.9961C2.26667 11.6628 4.86667 7.32943 10.9333 6.46276Z" fill="white" className="sm:group-hover:fill-[#4EF386]" />
                        </svg>
                      </div>
                      <span className="sm:group-hover:text-[#4EF386] text-white">
                        Share
                      </span>
                    </div>} data-sentry-element="ShareButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

                <ReviewActionsMenu id={id as string} bookSlug={book?.slug as string} isMine={user?.id === myUserId} shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${book?.slug}/reviews/${userData?.user?.username}`} reviewAuthorName={userData?.user?.username} data-sentry-element="ReviewActionsMenu" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </div>
              <AddToShelfButton buttonClassName="w-[160px] justify-start" bookId={bookId} initialStatus={book?.myReadingStatus?.status as ReadingStatusEnum} className="max-w-fit mt-4" data-sentry-element="AddToShelfButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
            </div>
          </div>
        </div>

        <div className="sm:hidden">
          {false ? <Skeleton className="mt-2.5 h-[174px] w-[728px]" /> : <ReadMoreText isReview contentJSON={content} mobileLength={640} desktopLength={640} className={cn("mb-4  whitespace-pre-line text-sm sm:text-base font-normal text-[#f4f4f4] sm:text-[#E6E6FA]", textClassName)} />}
          <div className="flex items-center gap-5 text-sm font-semibold text-white sm:font-normal">
            <LikeButton likedByMe={likedByMe as boolean} likesCount={likesCount as number} resourceId={id as string} resourceType={"review"} data-sentry-element="LikeButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
            <Link href={`${process.env.NEXT_PUBLIC_BASE_URL ?? ""}/books/${book?.slug ?? ""}/reviews/${userData?.user?.username ?? ""}`} className="flex items-center gap-1" data-sentry-element="Link" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                <path d="M8.99956 15.75C10.4718 15.7497 11.9035 15.268 13.0766 14.3785C14.2496 13.489 15.0997 12.2403 15.4973 10.8228C15.8948 9.40531 15.8181 7.89669 15.2787 6.52687C14.7393 5.15704 13.7668 4.00113 12.5095 3.23527C11.2522 2.46941 9.77897 2.1356 8.31434 2.28472C6.84971 2.43384 5.474 3.05771 4.39684 4.06125C3.31968 5.0648 2.60014 6.39298 2.34788 7.84341C2.09563 9.29384 2.32448 10.787 2.99956 12.0953L2.24956 15.75L5.90431 15C6.83131 15.4793 7.88431 15.75 8.99956 15.75Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                <path d="M5.625 9H5.6325V9.0075H5.625V9ZM9 9H9.0075V9.0075H9V9ZM12.375 9H12.3825V9.0075H12.375V9Z" stroke="white" strokeWidth="2" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </svg>
              <span className="text-sm font-semibold sm:font-normal">
                {formatToShortenNumber(commentsCount as number)}
              </span>
            </Link>

            <ShareButton shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${book?.slug}/reviews/${userData?.user?.username}`} trigger={<div className="group text-sm font-semibold text-white sm:font-normal mr-1.5 cursor-pointer flex items-center justify-center p-0">
                  <div className="sm:size-8 max-sm:mr-1 sm:group-hover:bg-white/[4%] rounded-[9999px] flex items-center justify-center">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9333 6.46276V2.99609L17 9.06276L10.9333 15.1294V11.5761C6.6 11.5761 3.56667 12.9628 1.4 15.9961C2.26667 11.6628 4.86667 7.32943 10.9333 6.46276Z" fill="white" />
                    </svg>
                  </div>
                  <span className=" text-white">Share</span>
                </div>} data-sentry-element="ShareButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

            <ReviewActionsMenu id={id as string} bookSlug={book?.slug as string} isMine={user?.id === myUserId} shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${book?.slug}/reviews/${userData?.user?.username}`} reviewAuthorName={userData?.user?.username} data-sentry-element="ReviewActionsMenu" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
          </div>
        </div>
      </CardContent>
    </Card>;
};
export default TrendingReviewsThisWeekCard;