const getImageUrl = (
  id: string,
  type: "avatar" | "banner" | "cover" = "avatar"
) => {
  if (type === "avatar") {
    return `https://images.kaguya.io/users/avatars/${id}-240w.webp`
  } else if (type === "banner") {
    return `https://images.kaguya.io/users/banners/${id}-1280w.webp`
  } else if (type === "cover") {
    return `https://images.kaguya.io/books/${id}-250w.webp`
  }
  return null
}

export default getImageUrl
