import React from "react";
const WarningIcon = () => {
  return <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0" data-sentry-element="svg" data-sentry-component="WarningIcon" data-sentry-source-file="WarningIcon.tsx">
      <path d="M13.7495 4.76546C14.9438 2.60089 18.0558 2.59974 19.2523 4.76546L29.8238 23.9083C30.9803 26.0032 29.4649 28.5712 27.0729 28.5712H5.9369C3.5449 28.5712 2.02833 26.0043 3.1849 23.9095L13.7495 4.76546Z" fill="#212121" data-sentry-element="path" data-sentry-source-file="WarningIcon.tsx" />
      <path d="M13.7495 4.76546C14.9438 2.60089 18.0558 2.59974 19.2523 4.76546L29.8238 23.9083C30.9803 26.0032 29.4649 28.5712 27.0729 28.5712H5.9369C3.5449 28.5712 2.02833 26.0043 3.1849 23.9095L13.7495 4.76546Z" fill="url(#paint0_linear_4991_47545)" data-sentry-element="path" data-sentry-source-file="WarningIcon.tsx" />
      <path d="M17.6451 22.8564C17.6451 23.1596 17.5247 23.4502 17.3104 23.6646C17.096 23.8789 16.8053 23.9993 16.5022 23.9993C16.1991 23.9993 15.9084 23.8789 15.6941 23.6646C15.4798 23.4502 15.3594 23.1596 15.3594 22.8564C15.3594 22.5533 15.4798 22.2627 15.6941 22.0483C15.9084 21.834 16.1991 21.7136 16.5022 21.7136C16.8053 21.7136 17.096 21.834 17.3104 22.0483C17.5247 22.2627 17.6451 22.5533 17.6451 22.8564ZM15.6451 19.1422C15.6451 19.3695 15.7354 19.5875 15.8961 19.7483C16.0569 19.909 16.2749 19.9993 16.5022 19.9993C16.7296 19.9993 16.9476 19.909 17.1083 19.7483C17.2691 19.5875 17.3594 19.3695 17.3594 19.1422V11.7136C17.3594 11.4863 17.2691 11.2682 17.1083 11.1075C16.9476 10.9468 16.7296 10.8564 16.5022 10.8564C16.2749 10.8564 16.0569 10.9468 15.8961 11.1075C15.7354 11.2682 15.6451 11.4863 15.6451 11.7136V19.1422Z" fill="url(#paint1_linear_4991_47545)" data-sentry-element="path" data-sentry-source-file="WarningIcon.tsx" />
      <defs data-sentry-element="defs" data-sentry-source-file="WarningIcon.tsx">
        <linearGradient id="paint0_linear_4991_47545" x1="7.07519" y1="-0.832256" x2="23.0306" y2="31.9506" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="WarningIcon.tsx">
          <stop stop-color="#FFCD0F" data-sentry-element="stop" data-sentry-source-file="WarningIcon.tsx" />
          <stop offset="1" stop-color="#FE8401" data-sentry-element="stop" data-sentry-source-file="WarningIcon.tsx" />
        </linearGradient>
        <linearGradient id="paint1_linear_4991_47545" x1="13.4542" y1="10.8564" x2="20.3171" y2="23.5856" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="WarningIcon.tsx">
          <stop stop-color="#4A4A4A" data-sentry-element="stop" data-sentry-source-file="WarningIcon.tsx" />
          <stop offset="1" stop-color="#242424" data-sentry-element="stop" data-sentry-source-file="WarningIcon.tsx" />
        </linearGradient>
      </defs>
    </svg>;
};
export default WarningIcon;