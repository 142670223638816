import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "@/lib/utils";
import { FaCheck } from "react-icons/fa";
const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  indicatorSize?: number;
}>(({
  className,
  indicatorSize,
  ...props
}, ref) => <CheckboxPrimitive.Root ref={ref} className={cn("peer h-full w-full shrink-0 rounded-[4px] border-2 border-white max-sm:border-[#5069ce] shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#232533] sm:data-[state=checked]:bg-[#5069ce] data-[state=checked]:text-white", className)} {...props}>
    <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
      {/* <CheckIcon className='h-3 w-3' />
       */}
      <FaCheck size={indicatorSize || 8} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };