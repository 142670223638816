"use client";

// import Image from "next/image"
// import { cn } from "@/lib/utils"
// import React from "react"
// import { IoBook } from "react-icons/io5"
// import { Book } from "@/graphql/generated/types"
// import Link from "next/link"

// type BookCoverProps = {
//   book?: {
//     primaryImageId?: Book["primaryImageId"]
//     title?: Book["title"]
//     slug?: Book["slug"]
//   }
//   hideFallback?: boolean
//   fallBackSize?: number
//   className?: string
//   style?: React.CSSProperties
// }

// const BookCover: React.FC<BookCoverProps> = (props) => {
//   const { book, hideFallback, fallBackSize, className, style, ...rest } = props

//   const imageWrapperClass = cn("relative aspect-[2/3] w-full", className)

//   const imageUrl =
//     book?.primaryImageId &&
//     `https://images.kaguya.io/books/${book.primaryImageId}-250w.webp`

//   return (
//     <Link href={book?.slug ? `/books/${book?.slug}` : ""}>
//       <div className={imageWrapperClass} style={{ ...style }}>
//         {imageUrl ? (
//           <Image
//             className={cn("object-cover object-center", imageWrapperClass)}
//             src={imageUrl}
//             alt={book.title || "Book cover"}
//             fill
//             {...rest}
//           />
//         ) : (
//           <BookCoverFallback
//             hideFallback={hideFallback}
//             fallBackSize={fallBackSize}
//           />
//         )}
//       </div>
//     </Link>
//   )
// }

// export default BookCover

// type BookCoverFallbackProps = {
//   fallBackSize?: number
//   hideFallback?: boolean
// }

// const BookCoverFallback = ({
//   fallBackSize,
//   hideFallback,
// }: BookCoverFallbackProps) => {
//   if (hideFallback) {
//     return null
//   }

//   return (
//     <div className="absolute inset-0 flex items-center justify-center bg-white/[6%] rounded-[6px]">
//       <IoBook size={fallBackSize || 44} className="fill-[#e6e6fa]/50" />
//     </div>
//   )
// }
import Image from "next/image";
import { cn } from "@/lib/utils";
import React from "react";
import { IoBook } from "react-icons/io5";
import { Book } from "@/graphql/generated/types";
import Link from "next/link";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useResponsive } from "@/hooks/useResponsive";
type BookCoverProps = {
  book?: {
    primaryImageId?: Book["primaryImageId"];
    title?: Book["title"];
    slug?: Book["slug"];
  };
  hideFallback?: boolean;
  fallBackSize?: number;
  mobileFallbackSize?: number;
  className?: string;
  style?: React.CSSProperties;
  openInDialog?: boolean;
  useDivInsteadOfLink?: boolean;
};
const BookCover: React.FC<BookCoverProps> = props => {
  const {
    book,
    hideFallback,
    fallBackSize,
    className,
    mobileFallbackSize,
    style,
    openInDialog = false,
    useDivInsteadOfLink = false,
    ...rest
  } = props;
  const {
    isMobile
  } = useResponsive();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const imageWrapperClass = cn("relative aspect-[2/3] w-full", className);
  const imageUrl = book?.primaryImageId && `https://images.kaguya.io/books/${book.primaryImageId}-250w.webp`;
  const Container = useDivInsteadOfLink ? "div" : Link;
  const href = book?.slug ? `/books/${book.slug}` : "";
  function handleClick(e: React.MouseEvent) {
    if (openInDialog) {
      e.preventDefault();
      setDialogOpen(true);
    }
  }
  return <>
      <Container href={!useDivInsteadOfLink ? href : ""} onClick={handleClick} data-sentry-element="Container" data-sentry-source-file="BookCover.tsx">
        <div className={imageWrapperClass} style={style}>
          {imageUrl ? <Image className={cn("object-cover object-center", imageWrapperClass)} src={imageUrl} alt={book?.title || "Book cover"} fill {...rest} /> : <BookCoverFallback isMobile={isMobile} hideFallback={hideFallback} mobileFallbackSize={mobileFallbackSize} fallBackSize={fallBackSize} />}
        </div>
      </Container>

      {openInDialog && <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent dialogCloseClassName="max-sm:-right-6 max-sm:-top-8 sm:hidden" className="max-sm:max-w-[300px] sm:max-w-fit bg-transparent items-center justify-center border-none p-0 transition-all duration-700">
            <div className="m-0 max-w-fit p-0 bg-transparent">
              {imageUrl && <div className="flex max-w-fit items-center justify-center">
                  <Image src={imageUrl} alt={book?.title || "Book cover"} width={isMobile ? 300 : 400} height={isMobile ? 450 : 600} className={cn("object-cover border-none object-center rounded", isMobile && "w-[300px] h-[450px]")} />
                </div>}
            </div>
          </DialogContent>
        </Dialog>}
    </>;
};
export default BookCover;
type BookCoverFallbackProps = {
  fallBackSize?: number;
  hideFallback?: boolean;
  isMobile?: boolean;
  mobileFallbackSize?: number;
};
const BookCoverFallback: React.FC<BookCoverFallbackProps> = ({
  fallBackSize,
  hideFallback,
  isMobile,
  mobileFallbackSize
}) => {
  if (hideFallback) {
    return null;
  }
  return <div className="absolute inset-0 flex items-center justify-center bg-white/[6%] rounded-[6px]" data-sentry-component="BookCoverFallback" data-sentry-source-file="BookCover.tsx">
      <IoBook size={isMobile ? mobileFallbackSize ? mobileFallbackSize : 36 : fallBackSize ? fallBackSize : 44} className="fill-[#e6e6fa]/50" data-sentry-element="IoBook" data-sentry-source-file="BookCover.tsx" />
    </div>;
};