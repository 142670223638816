"use client";

import React from "react";
import { PiStarFill } from "react-icons/pi";
import { Card, CardContent, CardDescription, CardTitle } from "../ui/card";
import Image from "next/image";
import useUIStore from "@/store/useUIStore";
import { Skeleton } from "../ui/skeleton";
const FEATURE_ITEMS = [{
  id: 1,
  title: "Write Reviews",
  description: "Share your thoughts and insights by writing detailed reviews.",
  icon: <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.169 2.05651C38.4122 2.03251 30.4714 3.22838 23.4052 6.28398C23.8932 7.51829 24.3032 8.95398 24.6416 10.5526C22.739 9.11888 20.3594 8.66091 18.255 8.95294C13.9219 11.6184 10.0865 15.169 7.05748 19.7899C9.41932 20.517 11.8922 21.9283 13.8867 23.9003C10.8989 23.2398 7.42666 23.3476 4.58782 24.1815C3.29941 26.871 2.24332 29.8515 1.46191 33.1522C13.5285 30.277 13.6984 33.4816 6.21973 46.3182C7.80195 47.5008 10.1743 47.6092 12.0234 46.1717C12.5625 25.1188 21.6965 30.5854 23.8301 33.24C25.1696 29.4347 27.0898 25.5019 29.7275 21.3836C28.1066 20.0086 26.0516 19.0551 23.7451 18.3485C26.1229 17.8557 28.5179 17.324 30.6944 17.2966C31.3021 17.2889 31.8925 17.3221 32.461 17.405C34.1082 15.1543 35.968 12.8476 38.0626 10.4703C36.8871 9.87873 35.6742 9.39648 34.4884 9.06713C36.0945 8.52713 37.7953 8.28526 39.4425 8.30241C39.6309 8.30429 39.8179 8.31132 40.005 8.31994C41.8865 6.28482 43.9356 4.19719 46.169 2.05622V2.05651ZM40.005 4.43232C27.7303 10.563 18.7588 19.7066 13.5877 31.2683C16.1569 17.2883 26.9107 7.92826 40.005 4.43232Z" fill="white" />
      </svg>
}, {
  id: 2,
  title: "Rate Books",
  description: "Express your opinions by rating each book on a ten-star scale. ",
  icon: <PiStarFill size={48} />
}, {
  id: 3,
  title: "Organize Shelves",
  description: `Arrange your books with custom shelves to easily manage your library.`,
  icon: "/icons/features/streamline_shelf-solid.svg"
}];
const Features = () => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <section data-sentry-component="Features" data-sentry-source-file="Features.tsx">
      {isSkeletonLoading ? <Skeleton className="h-10 mb-6 w-[300px]" /> : <h4 className="mb-4 sm:mb-6 text-xl font-bold  sm:text-2xl sm:font-semibold text-white">
          Kaguya lets you...
        </h4>}
      <div className="grid sm:grid-cols-3 gap-4 sm:gap-7">
        {FEATURE_ITEMS.map(item => <FeatureCard key={item.id} {...item} />)}
      </div>
    </section>;
};
export default Features;
interface FeatureCardProps {
  id: number;
  title: string;
  description: string;
  icon: React.ReactElement | string;
}
const FeatureCard = ({
  title,
  description,
  icon
}: FeatureCardProps) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <Card className="rounded-[8px] sm:border border-[#BDBDBD]/25 bg-[#232533] sm:bg-[#161724] p-2.5 pr-6 sm:py-8 sm:pl-8 sm:pr-10 shadow-none" data-sentry-element="Card" data-sentry-component="FeatureCard" data-sentry-source-file="Features.tsx">
      <CardContent className="max-sm:flex max-sm:gap-4 max-sm:items-center  sm:space-y-3 p-0" data-sentry-element="CardContent" data-sentry-source-file="Features.tsx">
        <div className="sm:mb-8 max-sm:p-3 max-sm:min-w-[72px] size-[72px] max-sm:rounded-[6px] max-sm:bg-white/[4%] max-sm:justify-center sm:h-12 sm:w-12 max-sm:flex max-sm:items-center text-white">
          {isSkeletonLoading ? <Skeleton className="h-12 w-12" /> : typeof icon === "string" ? <Image src={icon} width={48} height={48} alt="feature icon" /> : icon}
        </div>

        <div className="max-sm:-mt-1 sm:space-y-2">
          {isSkeletonLoading ? <Skeleton className="h-6 w-[250px]" /> : <CardTitle className="text-base sm:text-2xl font-bold sm:font-semibold text-white sm:text-[#f6f6f7]">
              {title}
            </CardTitle>}
          {isSkeletonLoading ? <Skeleton className="h-16 w-full" /> : <CardDescription className="text-xs sm:text-base leading-[22px] font-normal text-[#e0e0e0] sm:text-[#e6e6fa]">
              {description}
            </CardDescription>}
        </div>
      </CardContent>
    </Card>;
};