"use client";

import React from "react";
import { Button, buttonVariants } from "@/components/ui/button";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogClose } from "@/components/ui/dialog";
import axios, { AxiosError } from "axios";
import DOMPurify from "isomorphic-dompurify";
import { useQuery } from "@apollo/client";
import { useUser } from "@/contexts/user";
import { useRouter } from "next/navigation";
import { MyUsernameDocument } from "@/graphql/generated/types";
interface ReportButtonProps {
  title?: string;
  reportType: "user" | "review" | "book" | "comment" | "other";
  reportedEntityName: string;
  reportedEntityUrl: string;
  trigger?: React.ReactNode;
}
const REPORT_DATA = {
  user: {
    title: "Report User",
    reportType: "user",
    label: "Why are you reporting this user? ",
    description: "Think this user is violating our guidelines? Let us know. Your report will stay confidential."
  },
  review: {
    title: "Report Review",
    reportType: "review",
    label: "Why are you reporting this review?",
    description: "Does this review break our guidelines? Share the details, and we’ll look into it."
  },
  book: {
    title: "Report an Issue with This Book",
    reportType: "book",
    label: "What’s the issue?",
    description: "Noticed something wrong with this book? Tell us so we can fix it."
  },
  comment: {
    title: "Report Comment",
    reportType: "comment",
    label: "Why are you reporting this comment?",
    description: "Does this comment break our guidelines? Let us know so we can take a closer look."
  },
  other: {
    title: "Report a Problem",
    reportType: "other",
    label: "Why are you reporting this?",
    description: "At Kaguya, we care about our community's well-being. If you see behavior against our community guidelines, please report it so we can take action."
  }
};
const ReportButton: React.FC<ReportButtonProps> = ({
  title,
  reportType,
  reportedEntityName,
  reportedEntityUrl,
  trigger
}) => {
  const [reportText, setReportText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");
  const {
    user,
    isLoading
  } = useUser();
  const router = useRouter();
  const isLoggedIn = !isLoading && !!user;
  const {
    data: loggedInUserData
  } = useQuery(MyUsernameDocument, {
    skip: !isLoggedIn
  });
  const reporterUsername = loggedInUserData?.me?.username;
  const handleReportSubmit = async () => {
    if (!isLoggedIn) {
      router.replace("/login");
      return;
    }
    if (reportType === "user" && reporterUsername === reportedEntityName) {
      setError("You cannot report yourself.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const sanitizedReportText = DOMPurify.sanitize(reportText);
      const payload = {
        reportType,
        reportedEntityName,
        reportedEntityUrl,
        reporterUsername,
        reportText: sanitizedReportText
      };
      const response = await axios.post("/api/report", payload);
      if (response.status === 200) {
        setSuccess(true);
        setReportText("");
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err.response?.data.message);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };
  return <Dialog data-sentry-element="Dialog" data-sentry-component="ReportButton" data-sentry-source-file="ReportButton.tsx">
      <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="ReportButton.tsx">
        {trigger ? trigger : <Button onSelect={e => e.preventDefault()} className={buttonVariants({
        variant: "secondary",
        className: "w-full bg-transparent h-full pl-3.5 pr-[37px] flex items-center text-sm gap-[13px] py-4 cursor-pointer"
      })}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 11.25H13.3987C13.5413 11.2499 13.6808 11.2093 13.801 11.1328C13.9213 11.0563 14.0172 10.9471 14.0777 10.8181C14.1381 10.689 14.1605 10.5454 14.1423 10.4041C14.1241 10.2627 14.066 10.1295 13.9747 10.02L11.25 6.75L13.9747 3.48C14.066 3.3705 14.1241 3.23728 14.1423 3.09594C14.1605 2.9546 14.1381 2.81099 14.0777 2.68193C14.0172 2.55287 13.9213 2.4437 13.801 2.3672C13.6808 2.29071 13.5413 2.25005 13.3987 2.25H3V15.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {title ? title : "Report"}
          </Button>}
      </DialogTrigger>
      <DialogContent className="max-w-[480px] max-sm:max-w-[320px] gap-4 px-5 sm:px-10 pt-8 pb-6 border-none rounded-[16px] outline-none bg-[#10111e]" data-sentry-element="DialogContent" data-sentry-source-file="ReportButton.tsx">
        <DialogHeader className="flex flex-col" data-sentry-element="DialogHeader" data-sentry-source-file="ReportButton.tsx">
          <DialogTitle className="text-xl max-sm:text-left sm:text-2xl max-sm:self-start text-white font-bold sm:font-semibold" data-sentry-element="DialogTitle" data-sentry-source-file="ReportButton.tsx">
            {REPORT_DATA[reportType].title}
          </DialogTitle>
        </DialogHeader>

        <p className="text-[#f4f4f4] sm:text-[#e6e6fa]/70 max-sm:text-base font-normal sm:leading-[26px]">
          {REPORT_DATA[reportType].description}
        </p>

        <div className="flex flex-col gap-4">
          <label htmlFor="report-reason" className="text-xs font-base text-white">
            {REPORT_DATA[reportType].label}
          </label>
          <textarea id="report-reason" className="px-3 py-3.5 resize-none rounded-[8px] text-xs bg-[#161724] border outline-none border-[#bdbdbd]/10 min-h-[140px] text-[#e6e6fa]/90" value={reportText} onChange={e => setReportText(e.target.value)} disabled={loading || success} />
        </div>

        {error && <p className="text-red-400 text-sm">{error}</p>}
        {success && <p className="text-green-400 text-sm">
            Report submitted successfully.
          </p>}

        <DialogFooter className="flex max-sm:flex-row justify-end gap-2" data-sentry-element="DialogFooter" data-sentry-source-file="ReportButton.tsx">
          {!success && <>
              <DialogClose asChild>
                <Button type="button" variant="secondary" className="bg-white/[4%] px-[26px] py-3.5 text-sm text-white rounded-[8px]" disabled={loading}>
                  Cancel
                </Button>
              </DialogClose>
              <Button variant="primary" className="bg-[#5069ce] w-fit px-[26px] py-3.5 text-sm text-white rounded-[8px]" onClick={handleReportSubmit} disabled={loading || reportText.trim() === ""}>
                {loading ? "Submitting..." : "Report"}
              </Button>
            </>}
          {success && <DialogClose asChild>
              <Button type="button" variant="secondary" className="bg-white/[4%] px-[26px] py-3.5 text-sm text-white rounded-[8px]">
                Close
              </Button>
            </DialogClose>}
        </DialogFooter>
      </DialogContent>
    </Dialog>;
};
export default ReportButton;