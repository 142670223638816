"use client";

import { cn } from "@/lib/utils";
import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import TrendingReviewsThisWeekCard from "../shared/TrendingReviewsThisWeekCard";
import { Skeleton } from "../ui/skeleton";
import { TrendingReviewsWeeklyDocument } from "@/graphql/generated/types";
import { useSuspenseQuery } from "@apollo/client";
const TrendingReviewsThisWeek = ({
  className
}: {
  className?: string;
}) => {
  const {
    data
  } = useSuspenseQuery(TrendingReviewsWeeklyDocument, {
    // fetchPolicy: "cache-and-network",
  });
  const reviews = data?.trendingReviewsWeekly?.slice(0, 3) || [];
  const loading = false;
  return <div className={cn(" ", className)} data-sentry-component="TrendingReviewsThisWeek" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
      <div className="sm:mb-3 sm:border-b border-[#98c6f4]/20 pb-4 sm:pb-6">
        {loading ? <Skeleton className="h-12 max-sm:hidden w-[400px]" /> : <h3 className="text-xl font-bold sm:text-2xl sm:font-semibold leading-8 text-white">
            Trending Reviews This Week
          </h3>}
      </div>
      <Card className="shadow-none " data-sentry-element="Card" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
        <CardContent className="sm:divide-y max-sm:space-y-4  divide-[#98c6f4]/20 p-0 sm:pr-5" data-sentry-element="CardContent" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
          {reviews.map(review => {
          return <TrendingReviewsThisWeekCard key={review?.id} {...review} />;
        })}
        </CardContent>
      </Card>
    </div>;
};
export default TrendingReviewsThisWeek;